import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import { useTranslation } from "react-i18next";
import { fetchGameLabels } from "../../../functions/favouriteGameLabel";
import GameLabelDisplayer from "../game/gamelabel/view/GameLabelDisplayer";
import { extractGames } from "../../../functions/gameExtraction";
import { LABEL_STATUS_EXCLUDED, LABEL_STATUS_INCLUDED, LABEL_STATUS_NONE, getNextLabelStatus } from "../game/gamelabel/GameLabelStatus";

function GameRandomizer({ token, onError, onSearchProductClicked }) {
    const { t } = useTranslation('messages');

    const [labels, setLabels] = useState([]);
    const [checkedLabels, setCheckedLabels] = useState(new Map());
    const [numGamesToExtract, setNumGamesToExtract] = useState(1);
    const [extractedGames, setExtractedGames] = useState([]);

    useEffect(() => {
        fetchGameLabels(token, (data) => setLabels(data.labels), onError);
    }, [token, onError]);

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col xs="auto">
                            <h5>{t('private_area.random_games_tab.labels_title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <GameLabelDisplayer
                                button_id_prefix="GameRandomizer"
                                gameLabels={labels}
                                checkedLabels={checkedLabels}
                                checkLabel={(labelId) => onCheckLabelClicked(labelId, checkedLabels, setCheckedLabels)}
                            />
                        </Col>
                    </Row >
                    <Row>
                        <Col>
                            <Form>
                                <Form.Control
                                    type="text"
                                    placeholder={t('private_area.random_games_tab.number_of_games_placeholder')}
                                    defaultValue={numGamesToExtract}
                                    className="mt-3 mb-3"
                                    onChange={(e) => setNumGamesToExtract(parseInt(e.target.value))} />
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="mt-3"
                                    onClick={(e) => drawGames(e, token, labels, checkedLabels, numGamesToExtract, setExtractedGames, onError)}>
                                    {t('private_area.random_games_tab.draw_games_button_text')}
                                </Button>
                            </Form>
                        </Col>
                    </Row >
                </Container>
            </Container>
            <Container fluid>
                <Container>
                    {extractedGames.length > 0 &&
                        <Row className="mt-5">
                            <Col xs="auto">
                                <h5>{t('private_area.random_games_tab.result_title')}</h5>
                            </Col>
                        </Row>
                    }
                    <Row className="mt-3">
                        <Col>
                            <Table striped bordered hover responsive size="sm">
                                <tbody>
                                    {extractedGames.map((game) => (
                                        <tr key={game.link}>
                                            <td><Image src={game.image_link} /></td>
                                            <td><a href={game.link} target="_blank" rel="noreferrer">{game.name}</a></td>
                                            <td>{game.description}</td>
                                            <td>
                                                <Button
                                                    variant="primary"
                                                    type="button"
                                                    onClick={() => onSearchProductClicked(game.name)}>
                                                    {t('private_area.random_games_tab.result_search_button_text')}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

function onCheckLabelClicked(labelId, checkedLabels, setCheckedLabels) {
    let newCheckedLabels = new Map(checkedLabels);
    let curStatus = newCheckedLabels.has(labelId) ? newCheckedLabels.get(labelId) : 0;
    let nextStatus = getNextLabelStatus(curStatus);

    if (nextStatus === LABEL_STATUS_NONE) {
        newCheckedLabels.delete(labelId);
    } else {
        newCheckedLabels.set(labelId, nextStatus);
    }

    setCheckedLabels(newCheckedLabels)
}


function drawGames(e, token, labels, checkedLabels, numGamesToExtract, setExtractedGames, onError) {
    e.preventDefault();

    let game_filter_ids = Array.from(checkedLabels.entries()).filter(entry => entry[1] === LABEL_STATUS_INCLUDED).map(entry => entry[0]);
    let game_negative_filter_ids = Array.from(checkedLabels.entries()).filter(entry => entry[1] === LABEL_STATUS_EXCLUDED).map(entry => entry[0]);

    extractGames(token, (data) => {
        setExtractedGames(data.games);
    }, onError, game_filter_ids, game_negative_filter_ids, numGamesToExtract)
}

export default GameRandomizer;