import React from 'react';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import HomeMobile from './HomeMobile';
import HomeWeb from './HomeWeb';

function Home({ token }) {

    const { t } = useTranslation('messages');

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row className="justify-content-center">
                        <Col xs="auto">
                            <h3>{t('main.home.title')}</h3>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid>
                <Container className="pt-5 pb-5">
                    <div className="d-block d-md-none">{/*Visible only on xs and sm*/}
                        <HomeMobile token={token} />
                    </div>
                    <div className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                        <HomeWeb token={token} />
                    </div>
                </Container>
            </Container>
        </>
    );
}

export default Home;