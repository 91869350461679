import Logout from "../Logout";

function LoggedComponent({ loggedIn, setLoggedIn, children }) {
    if (loggedIn) {
        return children;
    } else {
        return (
            <Logout setLoggedIn={setLoggedIn} />
        );
    }

}

export default LoggedComponent;