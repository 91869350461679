import { invokeApi } from "../components/network/apiclient";
import { gameExtractionEndpoint } from "./endpoint";

export const extractGames = (token, onSuccess, onError, game_filters, game_negative_filters, num_extraction) => {
    let requestBody = {
        game_filters: game_filters,
        game_negative_filters: game_negative_filters,
        num_extraction: num_extraction
    };

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const apiRequest = new Request(gameExtractionEndpoint.extractPath(), myInit);

    invokeApi(apiRequest, onSuccess, onError);
}

export const retrieveExtractions = (token, onSuccess, onError, page_number) => {
    let requestBody = {
        page_number: page_number
    };

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const apiRequest = new Request(gameExtractionEndpoint.retrievePath(), myInit);

    invokeApi(apiRequest, onSuccess, onError);
}

export const deleteExtraction = (token, onSuccess, onError, game_extraction_id) => {
    let requestBody = {
        game_extraction_id: game_extraction_id
    };

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const apiRequest = new Request(gameExtractionEndpoint.deletePath(), myInit);

    invokeApi(apiRequest, onSuccess, onError);
}

export const selectGame = (token, onSuccess, onError, game_extraction_id, game_extraction_result_id, selected) => {
    let requestBody = {
        game_extraction_id: game_extraction_id,
        game_extraction_result_id: game_extraction_result_id,
        selected: selected
    };


    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const apiRequest = new Request(gameExtractionEndpoint.selectGamePath(), myInit);

    invokeApi(apiRequest, onSuccess, onError);
}