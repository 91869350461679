import React from 'react';
import { Button, Image, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FavouriteGameEditor from '../edit/FavouriteGameEditor';

function FavouriteGameListOnWeb(props) {
    const { t } = useTranslation('messages');

    return (
        <>
            <Table striped bordered hover responsive size="sm">
                <tbody>
                    {props.favouriteGames.map((favouriteGame) => (
                        <tr key={favouriteGame.favouriteGameId}>
                            <td>
                                <div id={favouriteGame.name.charAt(0) + "_web"}></div>
                                <Image src={favouriteGame.imageLink} />
                            </td>
                            <td>
                                <a href={favouriteGame.link} target="_blank" rel="noreferrer">{favouriteGame.name}
                                </a>
                            </td>
                            <td>
                                {favouriteGame.description}
                            </td>
                            <td>
                                {favouriteGame.gameLabels && favouriteGame.gameLabels.map((gameLabel) => (
                                    <Button
                                        key={gameLabel.gameLabelId}
                                        disabled={true}
                                        variant="secondary"
                                        className="mt-1 me-1">
                                        {gameLabel.name}
                                    </Button>
                                ))}
                            </td>
                            <td>
                                < Button
                                    variant="primary"
                                    onClick={() => props.onShowGameEditor(favouriteGame)}>
                                    {t('private_area.games_tab.games_edit_button_text')}
                                </Button>
                            </td>
                            <td>
                                <Button
                                    variant="primary"
                                    type="button"
                                    onClick={() => props.onSearchProductClicked(favouriteGame.name)}>
                                    {t('private_area.games_tab.games_serch_button_text')}
                                </Button>
                            </td>
                            <td>
                                <Button
                                    variant="danger"
                                    type="button"
                                    onClick={() => props.deleteGame(favouriteGame.favouriteGameId)}>
                                    {t('private_area.games_tab.games_delete_button_text')}
                                </Button>
                            </td>
                            <td>
                                {favouriteGame.creationTime ? new Date(favouriteGame.creationTime).toLocaleString() : null}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <FavouriteGameEditor
                gameLabels={props.gameLabels}
                associateLabels={props.associateLabels}
                showEditGame={props.showEditGame}
                underAssociationLabelsMap={props.underAssociationLabelsMap}
                setUnderAssociationLabelsMap={props.setUnderAssociationLabelsMap}
                underEditGame={props.underEditGame}
                onCloseGameEditor={props.onCloseGameEditor} />
        </>
    );
}

export default FavouriteGameListOnWeb;