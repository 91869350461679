import React from 'react';
import Table from 'react-bootstrap/Table'
import Image from 'react-bootstrap/Image'

function ProductSearchResultOnWeb({ products }) {
    return (
        <Table striped bordered hover responsive size="sm">
            <tbody>
                {products.map((product) => (
                    <tr key={product.link}>
                        <td><Image src={product.imageLink} /></td>
                        <td><a href={product.link} target="_blank" rel="noreferrer">{product.name}</a></td>
                        <td>{product.price}</td>
                        <td>{product.status}</td>
                        <td>{product.shopName}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
}

export default ProductSearchResultOnWeb;