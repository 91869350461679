

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import ErrorPopup from "./components/error/ErrorPopup";
import GameSearcher from "./components/gamesearch/GameSearcher";
import Home from "./components/home/Home";
import LoggedComponent from "./components/logged/Logged";
import Logout from "./components/Logout";
import Navigation from "./components/Navigation";
import PageNotFound from "./components/PageNotFound";
import GamesAndLabels from "./components/privatearea/game/GamesAndLabels";
import GameLink from "./components/privatearea/gamelink/GameLink";
import GameRandomizer from "./components/privatearea/gamerandomizer/GameRandomizer";
import PrivateArea from "./components/privatearea/PrivateArea";
import ProductSearcher, { PARAM_SEARCH_VALUE } from "./components/productsearch/ProductSearcher";
import PublicGamesAndLabels from "./components/public/game/PublicGamesAndLabels";
import { deleteAuthenticationInfo, getAccessToken, getCodeVerifier, isLoggedIn, setAuthenticationInfo } from './functions/authentication';
import { getDefaultLanguage, getLanguage, setLanguage } from "./functions/language";
import { ROUTE_ABOUT, ROUTE_GAMES_LIBRARY, ROUTE_GAME_EXTRACTIONS, ROUTE_GAME_SEARCH, ROUTE_LOGOUT, ROUTE_PRIVATE_AREA, ROUTE_PRODUCT_SEARCH, ROUTE_PUBLIC_GAMES, ROUTE_PUBLIC_LINK, ROUTE_RANDOM_GAME } from "./functions/route";
import GameExtractionDisplayer from "./components/privatearea/gameextraction/GameExtractionDisplayer";
import { getOAuth2Tokens } from './functions/cognito'
import { syncUserInfo } from "./functions/user";
import About from "./components/About";

export const PARAM_CODE = "code";

function App(props) {
  const [loggedIn, setLoggedIn] = useState(isLoggedIn());
  const [showError, setShowError] = useState(false);
  const [messageTitle, setMessageTitle] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);

  let navigate = useNavigate();

  const { t, i18n } = useTranslation('messages');

  useEffect(() => {
    if (!getLanguage()) {
      setLanguage(getDefaultLanguage());
      i18n.changeLanguage(getLanguage());
    }
  });

  let token = getAccessToken();
  const onError = useCallback((res, err) => {
    manageError(res, err, setShowError, setMessageError, setMessageTitle, setSessionExpired, t)
  }, [t]);

  const [searchParams, setSearchParams] = useSearchParams();
  let authorizationCode = searchParams.get(PARAM_CODE)

  useEffect(() => {
    if (isLoggedIn()) {
      syncUserInfo(
        () => { },
        onError,
        getAccessToken())
    } else if (authorizationCode) {
      getOAuth2Tokens(
        authorizationCode,
        getCodeVerifier(),
        (tokenResp) => {
          syncUserInfo(
            (userInfoResp => {
              setAuthenticationInfo(tokenResp.access_token, tokenResp.id_token, tokenResp.refresh_token, userInfoResp.user);
              setLoggedIn(true);
              searchParams.delete(PARAM_CODE);
              setSearchParams(searchParams);
            }),
            onError,
            tokenResp.access_token);
        },
        onError)
    }
  }, [token, authorizationCode, searchParams, setSearchParams, onError]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<Navigation />}>
          <Route
            index
            element={<Home token={token} onError={onError} />} />
          <Route
            path={ROUTE_PRODUCT_SEARCH}
            element={
              <LoggedComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <ProductSearcher token={token} onError={onError} />
              </LoggedComponent>
            } />
          <Route
            path={ROUTE_GAME_SEARCH}
            element={
              <LoggedComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <GameSearcher token={token} onError={onError} onSearchProductClicked={(gameName) => onSearchProductClicked(gameName, navigate)} />
              </LoggedComponent>
            } />
          <Route
            path={ROUTE_GAMES_LIBRARY}
            element={
              <LoggedComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <GamesAndLabels token={token} onError={onError} onSearchProductClicked={(gameName) => onSearchProductClicked(gameName, navigate)} />
              </LoggedComponent>
            } />
          <Route
            path={ROUTE_RANDOM_GAME}
            element={
              <LoggedComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <GameRandomizer token={token} onError={onError} onSearchProductClicked={(gameName) => onSearchProductClicked(gameName, navigate)} />
              </LoggedComponent>
            } />
          <Route
            path={ROUTE_GAME_EXTRACTIONS}
            element={
              <LoggedComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <GameExtractionDisplayer token={token} onError={onError} onSearchProductClicked={(gameName) => onSearchProductClicked(gameName, navigate)} />
              </LoggedComponent>
            } />
          <Route
            path={ROUTE_ABOUT}
            element={<About />}
          />


          <Route
            path={ROUTE_PRIVATE_AREA}
            element={
              <LoggedComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <PrivateArea />
              </LoggedComponent>
            } />
          <Route
            path={ROUTE_PUBLIC_LINK}
            element={
              <LoggedComponent loggedIn={loggedIn} setLoggedIn={setLoggedIn}>
                <GameLink token={token} onError={onError} />
              </LoggedComponent>
            } />
          <Route
            path={ROUTE_PUBLIC_GAMES}
            element={<PublicGamesAndLabels onError={onError} />} />
          isLoggedIn &&
          <Route
            path={ROUTE_LOGOUT}
            element={<Logout setLoggedIn={setLoggedIn} />} />)
          <Route
            path="*"
            element={<PageNotFound />} />
        </Route>
      </Routes>
      <ErrorPopup
        show={showError}
        handleClose={() => onCloseErrorPopup(setShowError, setMessageError, setMessageTitle, sessionExpired, setSessionExpired, setLoggedIn, navigate)}
        title={messageTitle}
        message={messageError} />
    </div>
  );
}

function manageError(res, err, setShowError, setMessageError, setMessageTitle, setSessionExpired, t) {
  let title = getErrorTitle(t);
  let msg = getErrorDescription(t);

  if (res) {
    console.log(res);

    title = getErrorTitle(t, res);
    msg = getErrorDescription(t, res);

    if (isSessionExpired(res)) {
      setSessionExpired(true);
    }
  } else if (err) {
    console.log(err);
  }

  setShowError(true);
  setMessageTitle(title);
  setMessageError(msg);
}

function onLogout(setLoggedIn, navigate) {
  deleteAuthenticationInfo();
  setLoggedIn(false);
  navigate("/");
}

function onSearchProductClicked(gameName, navigate) {
  let path = "/" + ROUTE_PRODUCT_SEARCH + "?" + PARAM_SEARCH_VALUE + "=" + encodeURIComponent(gameName);
  navigate(path);
}

function onCloseErrorPopup(setShowError, setMessageError, setMessageTitle, sessionExpired, setSessionExpired, setLoggedIn, navigate) {
  setShowError(false);
  setMessageTitle(null);
  setMessageError(null);

  if (sessionExpired) {
    setSessionExpired(false);
    onLogout(setLoggedIn, navigate);
  }
}

function getErrorTitle(t, res) {
  if (!res) {
    return t('error.popup.title_generic');
  }

  let errorCode = res.headers.get('error-code');

  if (errorCode === "3001") {
    return t('error.popup.title_session_expired');
  } else {
    return t('error.popup.title_generic');
  }
}

function getErrorDescription(t, res) {
  if (!res) {
    return t('error.popup.description_generic');
  }

  let errorCode = res.headers.get('error-code');

  if (errorCode === "3000") {
    return t('error.popup.description_bad_credentials');
  } else if (errorCode === "3001") {
    return t('error.popup.description_session_expired');
  } else {
    return t('error.popup.description_generic');
  }
}

function isSessionExpired(res) {
  return res.headers.get('error-code') === "3001";
}

export default App;
