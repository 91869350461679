import React from 'react';
import PublicFavouriteGameListOnMobile from './PublicFavouriteGameListOnMobile.js';
import PublicFavouriteGameListOnWeb from './PublicFavouriteGameListOnWeb.js';

function PublicGameLabelDisplayer({favouriteGames}) {
    return (
        <div>
            <div className="d-block d-md-none">{/*Visible only on xs and sm*/}
                <PublicFavouriteGameListOnMobile
                    favouriteGames={favouriteGames}
                />
            </div>
            <div className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                <PublicFavouriteGameListOnWeb
                    favouriteGames={favouriteGames}
                />
            </div>
        </div>
    );
}
export default PublicGameLabelDisplayer;