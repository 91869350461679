import { Trans, useTranslation } from 'react-i18next';
import { Col, Container, Image, Row } from "react-bootstrap";


function About() {
    const { t } = useTranslation('messages');

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col xs="auto">
                            <h5>{t('main.about.title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={4}>
                            <Image src="./images/profile.jpg" roundedCircle fluid />
                        </Col>
                        <Col xs={6}>
                            <Trans i18nKey='main.about.description' ns='messages'/>                     
                        </Col>
                    </Row>
                </Container >
            </Container >
        </>
    )
}

export default About;