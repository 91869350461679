import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { deleteFavouriteGameById, fetchFavouriteGames } from "../../../functions/favouriteGame";
import { addGameLabel, deleteGameLabel, fetchGameLabels, renameGameLabel, setGameLabels } from "../../../functions/favouriteGameLabel";
import BottomAnchor from "../BottomAnchor";
import FavouriteGameDisplayer from "./favouritegame/view/FavouriteGameDisplayer";
import GameLabelArea from "./gamelabel/GameLabelArea";
import { LABEL_STATUS_EXCLUDED, LABEL_STATUS_INCLUDED, LABEL_STATUS_NONE, getNextLabelStatus } from "./gamelabel/GameLabelStatus";

function GamesAndLabels({ token, onError, onSearchProductClicked }) {
    const { t } = useTranslation('messages');

    const [games, setGames] = useState([]);
    const [labels, setLabels] = useState([]);
    const [checkedLabels, setCheckedLabels] = useState(new Map());

    useEffect(() => {
        fetchFavouriteGames(token, (data) => setGames(data.gamesWithLabels), onError, {});
        fetchGameLabels(token, (data) => setLabels(data.labels), onError);
    }, [token, onError]);

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col xs="auto">
                            <h5>{t('private_area.games_tab.labels_title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <GameLabelArea
                                gameLabels={labels}
                                checkedLabels={checkedLabels}
                                addNewLabel={(labelName) => onAddGameLabelClicked(token, labelName, setLabels, onError)}
                                deleteLabel={(labelId) => onDeleteLabelClicked(token, labelId, checkedLabels, setLabels, setGames, onError)}
                                renameLabel={(labelId, newLabelName) => onRenameLabelClicked(token, labelId, newLabelName, checkedLabels, setLabels, setGames, onError)}
                                checkLabel={(labelId) => onCheckLabelClicked(token, labelId, checkedLabels, setCheckedLabels, setGames, onError)} />
                        </Col>
                    </Row >
                </Container>
            </Container>
            <Container fluid>
                <Container>
                    <Row className="mt-5">
                        <Col xs="auto">
                            <h5>{t('private_area.games_tab.games_title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3 mb-3">
                        <Col>
                            <FavouriteGameDisplayer
                                gameLabels={labels}
                                favouriteGames={games}
                                associateLabels={(gameId, labelIds) => onAssociateLabelsClicked(token, gameId, labelIds, checkedLabels, setLabels, setGames, onError)}
                                deleteGame={(gameName) => onDeleteGameClicked(token, gameName, setGames, onError)}
                                onSearchProductClicked={onSearchProductClicked}
                            />
                        </Col>
                    </Row >
                </Container>
            </Container>
            <BottomAnchor favouriteGames={games} />
        </>
    )
}

function fetchData(token, setGames, setLabels, onError, checkedLabels) {
    fetchFavouriteGames(token, (data) => setGames(data.gamesWithLabels), onError, getFilter(checkedLabels));
    fetchGameLabels(token, (data) => setLabels(data.labels), onError);
}

function onAddGameLabelClicked(token, labelName, setLabels, onError) {
    addGameLabel(token, (data) => setLabels(data.labels), onError, labelName);
}

function onDeleteLabelClicked(token, labelId, checkedLabels, setLabels, setGames, onError) {
    deleteGameLabel(token, (data) => {
        fetchData(token, setGames, setLabels, onError, checkedLabels)
    }, onError, labelId);
}

function onRenameLabelClicked(token, labelId, newLabelName, checkedLabels, setLabels, setGames, onError) {
    renameGameLabel(token, (data) => {
        fetchData(token, setGames, setLabels, onError, checkedLabels)
    }, onError, labelId, newLabelName);
}

function onCheckLabelClicked(token, labelId, checkedLabels, setCheckedLabels, setGames, onError) {
    let newCheckedLabels = new Map(checkedLabels);
    let curStatus = newCheckedLabels.has(labelId) ? newCheckedLabels.get(labelId) : 0;
    let nextStatus = getNextLabelStatus(curStatus);

    if (nextStatus === LABEL_STATUS_NONE) {
        newCheckedLabels.delete(labelId);
    } else {
        newCheckedLabels.set(labelId, nextStatus);
    }

    fetchFavouriteGames(token, (data) => {
        setGames(data.gamesWithLabels);
        setCheckedLabels(newCheckedLabels)
    }, onError, getFilter(newCheckedLabels));
}

function onAssociateLabelsClicked(token, gameId, labelIds, checkedLabels, setLabels, setGames, onError) {
    setGameLabels(token, (data) => {
        fetchData(token, setGames, setLabels, onError, checkedLabels)
    }, onError, gameId, labelIds);
}

function onDeleteGameClicked(token, gameId, setGames, onError) {
    deleteFavouriteGameById(token, (data) => setGames(data.games), onError, gameId);
}

function getFilter(checkedLabels) {
    let includedGameLabelIds = Array.from(checkedLabels.entries()).filter(entry => entry[1] === LABEL_STATUS_INCLUDED).map(entry => entry[0]);
    let excludedGameLabelIds = Array.from(checkedLabels.entries()).filter(entry => entry[1] === LABEL_STATUS_EXCLUDED).map(entry => entry[0]);

    return { includedGameLabelIds: includedGameLabelIds, excludedGameLabelIds: excludedGameLabelIds };
}

export default GamesAndLabels;