import { invokeApi } from "../components/network/apiclient";
import { authenticationEndpoint } from "./endpoint";

export const syncUserInfo = (onSuccess, onError, token) => {
    let requestBody = {
        token: token
    }

    let init = {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };
    let myRequest = new Request(authenticationEndpoint.syncUserInfoPath(), init);

    invokeApi(myRequest, onSuccess, onError);
}


