import { invokeApi } from "../components/network/apiclient";
import { gamesEndpoint } from "./endpoint";

export const fetchGames = (token, onSuccess, onError, search_value) => {
    let search_games_request = {
        name: search_value
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(search_games_request)
    };

    const myRequest = new Request(gamesEndpoint.searchPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}