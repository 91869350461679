const DEFAULT_LANGUAGE = 'en';

const SESSION_STORAGE_LANGUAGE_KEY = "lang";

export const getDefaultLanguage = () => {
    return DEFAULT_LANGUAGE;
}

export const getLanguage = () => {
    return window.localStorage.getItem(SESSION_STORAGE_LANGUAGE_KEY);
}

export const setLanguage = (lang) => {
    window.localStorage.setItem(SESSION_STORAGE_LANGUAGE_KEY, lang);
}