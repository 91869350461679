import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row, Toast } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getWebsiteUrl } from "../../../functions/endpoint";
import { disableLink, generateLink, getLink } from "../../../functions/gameLink";

const PUBLIC_GAMES_PATH = "/public/games";

function GameLink({ token, onError }) {
    const { t } = useTranslation('messages');

    const [gameLinkActive, setGameLinkActive] = useState(false);
    const [gameLink, setGameLink] = useState("");
    const [showCopyToast, setShowCopyToast] = useState(false);

    useEffect(() => {
        getLink(
            token,
            (data) => {
                setGameLink(buildGameLinkUrl(data.enabled, data.gameLinkId));
                setGameLinkActive(data.enabled);
            },
            onError)
    }, [token, onError]);

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col xs="auto">
                            <h5>{t('private_area.game_link_tab.link_title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form>
                                <Form.Check
                                    type="switch"
                                    label={t('private_area.game_link_tab.switch_label')}
                                    checked={gameLinkActive}
                                    onChange={(e) => onSetGameLinkClicked(token, e.target.checked, setGameLinkActive, setGameLink, onError)}
                                />
                                <Form.Group className="mt-3 mb-3">
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            defaultValue={gameLink}
                                            disabled={true}
                                        />
                                        <Button onClick={() => onCopyAllClicked(gameLink, setShowCopyToast)} disabled={!gameLink}>
                                            {t('private_area.game_link_tab.copy_all_button_text')}
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Toast onClose={() => setShowCopyToast(false)} show={showCopyToast} delay={2000} autohide>
                        <Toast.Body className="text-black">{t('private_area.game_link_tab.link_copied_toast_text')}</Toast.Body>
                    </Toast>
                </Container>
            </Container>
        </>
    );

}

function onCopyAllClicked(gameLink, setShowCopyToast) {
    navigator.clipboard.writeText(gameLink);
    setShowCopyToast(true);
}

function buildGameLinkUrl(enabled, gameLinkId) {
    if (enabled) {
        return getWebsiteUrl() + PUBLIC_GAMES_PATH + "?game_link_id=" + gameLinkId;
    } else {
        return "";
    }
}

function onSetGameLinkClicked(token, checked, setGameLinkActive, setGameLink, onError) {
    if (checked) {
        generateLink(
            token,
            (data) => {
                setGameLink(buildGameLinkUrl(true, data.gameLinkId));
                setGameLinkActive(true);
            },
            onError)
    } else {
        disableLink(
            token,
            (data) => {
                setGameLink("");
                setGameLinkActive(false);
            },
            onError
        );
    }
}

export default GameLink;