import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchPublicFavouriteGames, fetchPublicLabels, retrievePublicUserInfo } from "../../../functions/publicapi";
import { ROUTE_PAGE_NOT_FOUND } from "../../../functions/route";
import Loader from "../../Loader";
import BottomAnchor from "../../privatearea/BottomAnchor";
import PublicFavouriteGameDisplayer from "./game/PublicFavouriteGameDisplayer";
import PublicGameLabelDisplayer from "./gamelabel/PublicGameLabelDisplayer";
import { LABEL_STATUS_EXCLUDED, LABEL_STATUS_INCLUDED, LABEL_STATUS_NONE, getNextLabelStatus } from "../../privatearea/game/gamelabel/GameLabelStatus";

function PublicGamesAndLabels({ onError }) {
    const { t } = useTranslation('messages');
    let navigate = useNavigate();

    const [searchParams] = useSearchParams();
    let gameLinkId = searchParams.get('game_link_id')


    const [user, setUser] = useState("");
    const [games, setGames] = useState([]);
    const [labels, setLabels] = useState([]);
    const [checkedLabels, setCheckedLabels] = useState(new Map());

    useEffect(() => {
        fetchPublicFavouriteGames(gameLinkId, (data) => setGames(data.gamesWithLabels), () => navigate("/" + ROUTE_PAGE_NOT_FOUND), {});
        fetchPublicLabels(gameLinkId, (data) => setLabels(data.labels), () => navigate("/" + ROUTE_PAGE_NOT_FOUND));
        retrievePublicUserInfo(gameLinkId, (data) => setUser(data.user), () => navigate("/" + ROUTE_PAGE_NOT_FOUND));
    }, [gameLinkId, navigate]);

    if (!user) {
        return (
            <>
                <Container>
                    <Row className="mt-3 justify-content-center">
                        <Col xs="auto">
                            <Loader isError={false}></Loader>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col>
                            <h3>{t('public_area.games.owner', { name: user.firstname })}</h3>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <h5>{t('public_area.games.labels_title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <PublicGameLabelDisplayer
                                gameLabels={labels}
                                checkedLabels={checkedLabels}
                                checkLabel={(labelId) => onCheckLabelClicked(gameLinkId, labelId, checkedLabels, setCheckedLabels, setGames, onError)} />
                        </Col>
                    </Row >
                </Container>
            </Container>
            <Container fluid>
                <Container>
                    <Row className="mt-3">
                        <Col>
                            <h5>{t('private_area.games_tab.games_title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <PublicFavouriteGameDisplayer
                                favouriteGames={games}
                            />
                        </Col>
                    </Row >
                </Container>
            </Container>
            <BottomAnchor favouriteGames={games} />
        </>
    )
}

function onCheckLabelClicked(gameLinkId, labelId, checkedLabels, setCheckedLabels, setGames, onError) {
    let newCheckedLabels = new Map(checkedLabels);
    let curStatus = newCheckedLabels.has(labelId) ? newCheckedLabels.get(labelId) : 0;
    let nextStatus = getNextLabelStatus(curStatus);

    if (nextStatus === LABEL_STATUS_NONE) {
        newCheckedLabels.delete(labelId);
    } else {
        newCheckedLabels.set(labelId, nextStatus);
    }

    fetchPublicFavouriteGames(gameLinkId, (data) => {
        setGames(data.gamesWithLabels);
        setCheckedLabels(newCheckedLabels)
    }, onError, getFilter(newCheckedLabels));
}

function getFilter(checkedLabels) {
    let includedGameLabelIds = Array.from(checkedLabels.entries()).filter(entry => entry[1] === LABEL_STATUS_INCLUDED).map(entry => entry[0]);
    let excludedGameLabelIds = Array.from(checkedLabels.entries()).filter(entry => entry[1] === LABEL_STATUS_EXCLUDED).map(entry => entry[0]);

    return { includedGameLabelIds: includedGameLabelIds, excludedGameLabelIds: excludedGameLabelIds };
}

export default PublicGamesAndLabels;