import React from 'react';
import { ToggleButton } from "react-bootstrap";
import { LABEL_STATUS_EXCLUDED } from '../../../privatearea/game/gamelabel/GameLabelStatus';

function PublicGameLabelDisplayer({ gameLabels, checkedLabels, checkLabel }) {
    return (
        <>
            {
                gameLabels.map((gameLabel) => {
                    let checked = checkedLabels.has(gameLabel.gameLabelId);
                    let excluded = checkedLabels.get(gameLabel.gameLabelId) === LABEL_STATUS_EXCLUDED;
                    let buttonText = excluded ? (<s>{gameLabel.name}</s>) : (<>{gameLabel.name}</>);
                    let variant;
                    if (checked && excluded) {
                        variant = "outline-danger";
                    } else if (checked) {
                        variant = "outline-primary";
                    } else {
                        variant = "outline-secondary";
                    }

                    return (
                        <ToggleButton
                            id={gameLabel.gameLabelId}
                            key={gameLabel.gameLabelId}
                            type="checkbox"
                            variant={variant}
                            value={gameLabel.gameLabelId}
                            className="mt-1 me-1"
                            checked={checked}
                            onChange={() => checkLabel(gameLabel.gameLabelId)}>
                            {buttonText}
                        </ToggleButton>
                    )
                })
            }
        </>
    );
}

export default PublicGameLabelDisplayer;