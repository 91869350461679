import { Button, Form, Offcanvas } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function FavouriteGameEditor(props) {
    const { t } = useTranslation('messages');

    if (props.showEditGame) {
        let game_labels = (props.gameLabels.map((gameLabel) => (
            <Form.Check
                key={gameLabel.gameLabelId}
                type="checkbox"
                label={gameLabel.name}
                defaultChecked={props.underAssociationLabelsMap.get(gameLabel.gameLabelId)}
                onClick={(e) => {
                    let newMap = new Map(props.underAssociationLabelsMap);
                    newMap.set(gameLabel.gameLabelId, e.target.checked);
                    props.setUnderAssociationLabelsMap(newMap);
                }}
            />
        )));

        return (
            <>
                <Offcanvas show={props.showEditGame} onHide={props.onCloseGameEditor} placement="bottom">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{props.underEditGame.name}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Form>
                            {game_labels}
                            <Button
                                variant="primary"
                                type="submit"
                                onClick={(e) => onAssociateLabels(e, props.associateLabels, props.underEditGame.favouriteGameId, props.underAssociationLabelsMap, props.onCloseGameEditor)}                            >
                                {t('private_area.games_tab.games_associate_labels_button_text')}
                            </Button>
                        </Form>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    } else {
        return (<></>);
    }
}

function onAssociateLabels(e, associateLabels, gameId, underAssociationLabelsMap, onCloseGameEditor) {
    e.preventDefault();

    let gameLabelIds = [];

    for (let [gameId, checked] of underAssociationLabelsMap.entries()) {
        if (checked) {
            gameLabelIds.push(gameId);
        }
    }

    associateLabels(gameId, gameLabelIds);
    onCloseGameEditor();
}

export default FavouriteGameEditor;