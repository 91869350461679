import { invokeApi } from "../components/network/apiclient";
import { gameLinkEndpoint } from "./endpoint";

export const getLink = (token, onSuccess, onError) => {
    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const myRequest = new Request(gameLinkEndpoint.getPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const disableLink = (token, onSuccess, onError) => {
    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const myRequest = new Request(gameLinkEndpoint.disablePath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const generateLink = (token, onSuccess, onError) => {
    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const myRequest = new Request(gameLinkEndpoint.generatePath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
};