import { Col, Container, Row } from "react-bootstrap";
import PrivateAreaMobile from "./PrivateAreaMobile";
import PrivateAreaWeb from "./PrivateAreaWeb";

function PrivateArea({ token, onError }) {
    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row className="justify-content-center">
                        <Col xs="auto">
                            <h3>Private Area</h3>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid>
                <Container className="pt-5 pb-5">
                    <div className="d-block d-md-none">{/*Visible only on xs and sm*/}
                        <PrivateAreaMobile />
                    </div>
                    <div className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                        <PrivateAreaWeb />
                    </div>
                </Container>
            </Container>
        </>
    );
}

export default PrivateArea;