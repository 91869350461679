import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { deleteAuthenticationInfo } from "../functions/authentication";

function Logout({ setLoggedIn }) {
    let navigate = useNavigate();
    useEffect(() => {
        deleteAuthenticationInfo();
        setLoggedIn(false);
        navigate("/");
    });
}

export default Logout;