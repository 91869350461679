import { useEffect, useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { addFavouriteGame, deleteFavouriteGameByLink, fetchFavouriteGames } from "../../functions/favouriteGame";
import { fetchGames } from "../../functions/game";
import Loader from "../Loader";
import GameSearchResultDisplayer from "./GameSearchResultDisplayer";
import FavouriteGameEditor from "../privatearea/game/favouritegame/edit/FavouriteGameEditor";
import { fetchGameLabels, setGameLabels } from "../../functions/favouriteGameLabel";

const LoadingStatus = Object.freeze({
    'LOADED': 'LOADED',
    'LOADING': 'LOADING',
});

function GameSearcher({ token, onError, onSearchProductClicked }) {
    const { t } = useTranslation('messages');

    const [games, setGames] = useState([]);
    const [favouriteGames, setFavouriteGames] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.LOADED);
    const [query, setQuery] = useState("");
    const [labels, setLabels] = useState([]);
    const [showEditGame, setShowEditGame] = useState(false);
    const [underEditGame, setUnderEditGame] = useState(null);
    const [underAssociationLabelsMap, setUnderAssociationLabelsMap] = useState(new Map());
    const [checkedLabelIds, setCheckedLabelIds] = useState([]);

    useEffect(() => {
        fetchFavouriteGames(token, (data) => setFavouriteGames(data.gamesWithLabels), onError, {});
        fetchGameLabels(token, (data) => setLabels(data.labels), onError);
    }, [token, onError]);

    let search_result;

    if (loadingStatus === LoadingStatus.LOADED) {
        search_result = (
            <Row className="mt-3">
                <Col>
                    <GameSearchResultDisplayer
                        games={games}
                        favouriteGames={favouriteGames}
                        onAddGameClicked={(game) => onAddGameClicked(token, game, addFavouriteGame, setFavouriteGames, onError)}
                        onDeleteGameClicked={(game) => onDeleteGameClicked(token, game, deleteFavouriteGameByLink, setFavouriteGames, onError)}
                        onSearchProductClicked={onSearchProductClicked}
                        onShowGameEditor={(game) => onShowGameEditor(game, setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap)}
                    />
                </Col>
            </Row>
        );
    } else {
        search_result = (
            <Row className="mt-3 justify-content-center">
                <Col xs="auto">
                    <Loader />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col xs="auto">
                            <h5>{t('main.game_search.title')}</h5>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form>
                                <Form.Group
                                    className="mb-3"
                                    controlId={"SearchGameFormGroup"}>
                                    <InputGroup>
                                        <Form.Control
                                            type="text"
                                            value={query}
                                            placeholder={t('main.game_search.search_form_placeholder')}
                                            onChange={e => setQuery(e.target.value)}
                                        />
                                        <Button onClick={() => setQuery("")}>
                                            {t('main.game_search.search_form_delete_query_button_text')}
                                        </Button>
                                    </InputGroup>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    onClick={(e) => onSearchGamesClicked(
                                        e,
                                        token,
                                        query,
                                        setGames,
                                        setLoadingStatus,
                                        onError)}>
                                    {t('main.game_search.search_form_button_text')}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </Container >
            </Container >
            <Container fluid>
                <Container>
                    {search_result}
                </Container>
            </Container>
            <FavouriteGameEditor
                gameLabels={labels}
                associateLabels={(gameId, labelIds) => onAssociateLabelsClicked(token, gameId, labelIds, checkedLabelIds, setLabels, setFavouriteGames, onError)}
                showEditGame={showEditGame}
                underAssociationLabelsMap={underAssociationLabelsMap}
                setUnderAssociationLabelsMap={setUnderAssociationLabelsMap}
                underEditGame={underEditGame}
                onCloseGameEditor={() => onCloseGameEditor(setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap)} />
        </>
    )
}

function onAddGameClicked(token, game, addFavouriteGame, setFavouriteGames, onError) {
    addFavouriteGame(token, (data) => setFavouriteGames(data.games), onError, game);
}

function onDeleteGameClicked(token, game, deleteFavouriteGameByLink, setFavouriteGames, onError) {
    deleteFavouriteGameByLink(token, (data) => setFavouriteGames(data.games), onError, game);
}

function onSearchGamesClicked(e, token, search_value, setGames, setLoadingStatus, onError) {
    e.preventDefault();

    if (!search_value) {
        return;
    }

    setGames([]);
    setLoadingStatus(LoadingStatus.LOADING);

    fetchGames(
        token,
        (data) => {
            setGames(data.games);
            setLoadingStatus(LoadingStatus.LOADED);
        },
        (res, err) => {
            setGames([]);
            setLoadingStatus(LoadingStatus.LOADED);
            onError(res, err);
        },
        search_value);
}

function fetchData(token, setFavouriteGames, setLabels, onError, checkedLabelIds) {
    fetchFavouriteGames(token, (data) => setFavouriteGames(data.gamesWithLabels), onError, { gameLabelIds: checkedLabelIds });
    fetchGameLabels(token, (data) => setLabels(data.labels), onError);
}

function onAssociateLabelsClicked(token, gameId, labelIds, checkedLabelIds, setLabels, setFavouriteGames, onError) {
    setGameLabels(token, (data) => {
        fetchData(token, setFavouriteGames, setLabels, onError, checkedLabelIds)
    }, onError, gameId, labelIds);
}


function onShowGameEditor(game, setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap) {
    setShowEditGame(true);
    setUnderEditGame(game);

    let map = game.gameLabels.reduce((map, gameLabel) => {
        map.set(gameLabel.gameLabelId, true);
        return map;
    }, new Map());

    setUnderAssociationLabelsMap(map)
}

function onCloseGameEditor(setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap) {
    setShowEditGame(false);
    setUnderEditGame(null);
    setUnderAssociationLabelsMap(new Map());
}

export default GameSearcher;