import React from 'react';
import GameSearchResultOnMobile from './GameSearchResultOnMobile.js';
import GameSearchResultOnWeb from './GameSearchResultOnWeb.js';
import { useTranslation } from 'react-i18next';

function GameSearchResultDisplayer(props) {
    const { t } = useTranslation('messages');

    return (
        <div>
            <div className="d-block d-md-none">{/*Visible only on xs and sm*/}
                <GameSearchResultOnMobile
                    games={props.games}
                    favouriteGames={props.favouriteGames}
                    onAddGameClicked={props.onAddGameClicked}
                    onDeleteGameClicked={props.onDeleteGameClicked}
                    onSearchProductClicked={props.onSearchProductClicked}
                    onShowGameEditor={props.onShowGameEditor}
                />
            </div>
            <div className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                <GameSearchResultOnWeb
                    games={props.games}
                    favouriteGames={props.favouriteGames}
                    onAddGameClicked={props.onAddGameClicked}
                    onDeleteGameClicked={props.onDeleteGameClicked}
                    onSearchProductClicked={props.onSearchProductClicked}
                    onShowGameEditor={props.onShowGameEditor}
                />
            </div>
            <div>{t('main.game_search.powered_by')}</div>
        </div>
    )

}

export default GameSearchResultDisplayer;