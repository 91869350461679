import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_GAMES_LIBRARY, ROUTE_GAME_SEARCH, ROUTE_PRODUCT_SEARCH } from "../../functions/route";

function HomeMobile({ token }) {
    const { t } = useTranslation('messages');
    let navigate = useNavigate();

    return (
        <>
            <Row style={{ height: 400 }}>
                <Col xs={12} className="square border border-dark rounded border-2 text-center mb-5">
                    {getGameSearchBox(t, token, navigate)}
                </Col>
                <Col xs={12} className="square border border-dark rounded border-2 text-center mb-5">
                    {getGameLibraryBox(t, token, navigate)}
                </Col>
                <Col xs={12} className="square border border-dark rounded border-2 text-center">
                    {getBuyGameBox(t, token, navigate)}
                </Col>
            </Row>
        </>
    );
}

function getGameSearchBox(t, token, navigate) {
    let box = (
        <Container>
            <Row className="pt-2 pb-2">
                <Col>
                    <img src="./images/game-search.png" alt={t('main.home.search_games_box_title')} height="60" />
                </Col>
                <Col>
                    <h5>{t('main.home.search_games_box_title')}</h5>
                    <br />
                    <span>{t('main.home.search_games_box_description')}</span>
                </Col>
            </Row>
        </Container>
    );

    return getBox(token, ROUTE_GAME_SEARCH, navigate, box);
}

function getGameLibraryBox(t, token, navigate) {
    let box = (
        <Container>
            <Row className="pt-2 pb-2">
                <Col>
                    <img src="./images/game-library.png" alt={t('main.home.games_box_title')} height="60" />
                </Col>
                <Col>
                    <h5>{t('main.home.games_box_title')}</h5>
                    <br />
                    <span>{t('main.home.games_box_description')}</span>
                </Col>
            </Row>
        </Container>
    );

    return getBox(token, ROUTE_GAMES_LIBRARY, navigate, box);
}

function getBuyGameBox(t, token, navigate) {
    let box = (
        <Container>
            <Row className="pt-2 pb-2">
                <Col>
                    <img src="./images/buy-game.png" alt={t('main.home.buy_games_box_title')} height="60" />
                </Col>
                <Col>
                    <h5>{t('main.home.buy_games_box_title')}</h5>
                    <br />
                    <span>{t('main.home.buy_games_box_description')}</span>
                </Col>
            </Row>
        </Container>
    );

    return getBox(token, ROUTE_PRODUCT_SEARCH, navigate, box);
}

function getBox(token, path, navigate, box) {
    if (token) {
        return (
            <Button variant="outline-light" className="text-black" onClick={() => navigate("/" + path)}>
                {box}
            </Button>
        );
    } else {
        return box;
    }
}

export default HomeMobile;