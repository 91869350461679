import React, { useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import GameLabelRenamer from "./GameLabelRenamer";

function GameLabelEditor(props) {
    const { t } = useTranslation('messages');

    const [newLabel, setNewLabel] = useState(null);

    return (
        <>
            <Form className="mb-3">
                <Form.Group className="mb-3" controlId="AddGameLabelFormGroup">
                    <Form.Control
                        type="text"
                        placeholder={t('private_area.games_tab.labels_new_form_placeholder')}
                        defaultValue={newLabel}
                        onChange={e => setNewLabel(e.target.value)}
                    />
                </Form.Group>
                <Button
                    variant="primary"
                    type="submit"
                    onClick={(e) => submitNewLabel(e, props.addNewLabel, newLabel, setNewLabel)}>
                    {t('private_area.games_tab.labels_new_form_button_text')}
                </Button>
            </Form>
            {props.gameLabels.map((gameLabel) => (
                <div key={gameLabel.gameLabelId}>
                    <div>{gameLabel.name}</div>
                    <Button
                        variant="danger"
                        onClick={() => props.deleteLabel(gameLabel.gameLabelId)}
                        className="mb-1 me-1">
                        {t('private_area.games_tab.labels_delete_button_text')}
                    </Button>
                    <GameLabelRenamer
                        gameLabel={gameLabel}
                        renameLabel={props.renameLabel} />
                </div>
            ))}
        </>
    );
}

function submitNewLabel(e, addNewLabel, newLabel, setNewLabel) {
    e.preventDefault();

    if (!newLabel) {
        return;
    }

    addNewLabel(newLabel);
}

export default GameLabelEditor;