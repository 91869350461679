import { useTranslation } from "react-i18next";
import { deleteExtraction, retrieveExtractions, selectGame } from "../../../functions/gameExtraction";
import { useEffect, useState } from "react";
import { Accordion, Col, Container, Pagination, Row } from "react-bootstrap";
import GameExtractionOnMobile from "./GameExtractionOnMobile";
import GameExtractionOnWeb from "./GameExtractionOnWeb";

function GameExtractionDisplayer({ token, onError, onSearchProductClicked }) {
    const { t } = useTranslation('messages');

    const [extractions, setExtractions] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        retrieveExtractions(token, (data) => setExtractions(data), onError, page);
    }, [token, onError, page]);

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col xs="auto">
                            <h5>{t('private_area.game_extractions_tab.title')}</h5>
                        </Col>
                    </Row>
                    <Accordion alwaysOpen className="d-block d-md-none">{/*Visible only on xs and sm*/}
                        {
                            extractions.map((extraction, index) => (
                                <GameExtractionOnMobile
                                    token={token}
                                    onError={onError}
                                    accordionIndex={index}
                                    extraction={extraction}
                                    key={index}
                                    onSearchProductClicked={onSearchProductClicked}
                                    onDeleteExtractionClicked={(e) => {
                                        onDeleteExtractionClicked(e, token, onError, extractions, setExtractions, extraction.game_extraction_id)
                                    }}
                                    onSelectGameClicked={(e, game_extraction_id, favourite_game_id, selected) => {
                                        onSelectGameClicked(e, token, onError, extractions, setExtractions, game_extraction_id, favourite_game_id, selected)
                                    }} />
                            ))
                        }
                    </Accordion>
                    <Accordion alwaysOpen className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                        {

                            extractions.map((extraction, index) => (
                                <GameExtractionOnWeb
                                    token={token}
                                    onError={onError}
                                    accordionIndex={index}
                                    extraction={extraction}
                                    key={index}
                                    onSearchProductClicked={onSearchProductClicked}
                                    onDeleteExtractionClicked={(e) => {
                                        onDeleteExtractionClicked(e, token, onError, extractions, setExtractions, extraction.game_extraction_id)
                                    }}
                                    onSelectGameClicked={(e, game_extraction_id, game_extraction_result_id, selected) => {
                                        onSelectGameClicked(e, token, onError, extractions, setExtractions, game_extraction_id, game_extraction_result_id, selected)
                                    }} />
                            ))
                        }
                    </Accordion>
                    <Pagination>
                        <Pagination.Prev onClick={() => changePage(setPage, page - 1)} />
                        <Pagination.Item onClick={() => changePage(setPage, page)}>{page}</Pagination.Item>
                        <Pagination.Next onClick={() => changePage(setPage, page + 1)} />
                    </Pagination>
                </Container>
            </Container >
        </>
    );
}

function onDeleteExtractionClicked(e, token, onError, extractions, setExtractions, gameExtractionId) {
    e.preventDefault();

    deleteExtraction(token, () => {
        setExtractions(extractions.filter((extraction) => extraction.game_extraction_id !== gameExtractionId));
    }, onError, gameExtractionId);
}

function onSelectGameClicked(e, token, onError, extractions, setExtractions, game_extraction_id, game_extraction_result_id, selected) {
    e.preventDefault();

    selectGame(token, () => {
        let game_extraction = extractions.filter(result => result.game_extraction_id === game_extraction_id)[0];
        let game = game_extraction.games.filter(result => result.game_extraction_result_id === game_extraction_result_id)[0];
        game.selected = selected
        setExtractions([...extractions])
    }, onError, game_extraction_id, game_extraction_result_id, selected);

}

function changePage(setPage, newPage) {
    if (newPage >= 0) {
        setPage(newPage);
    }
}

export default GameExtractionDisplayer;