import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useTranslation } from 'react-i18next';

function PageNotFound(props) {
    const { t } = useTranslation('messages');

    return (
        <Container>
            <Row className="mt-3 justify-content-center">
                <Col xs="auto">
                    <h1>{t('main.page_not_found.body')}</h1>
                </Col>
            </Row>
        </Container>
    )
}

export default PageNotFound;