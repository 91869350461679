import { invokeApi } from '../components/network/apiclient';
import { gameLabelAssociationEndpoint, gameLabelsEndpoint } from '../functions/endpoint'

export const fetchGameLabels = (token, onSuccess, onError) => {
    const myInit = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    };

    const myRequest = new Request(gameLabelsEndpoint.listPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const addGameLabel = (token, onSuccess, onError, labelName) => {
    let requestBody = {
        name: labelName
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(gameLabelsEndpoint.addPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const deleteGameLabel = (token, onSuccess, onError, gameLabelId) => {
    let requestBody = {
        gameLabelId: gameLabelId
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(gameLabelsEndpoint.deletePath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const renameGameLabel = (token, onSuccess, onError, gameLabelId, newLabelName) => {
    let requestBody = {
        gameLabelId: gameLabelId,
        newLabelName: newLabelName
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(gameLabelsEndpoint.renamePath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const setGameLabels = (token, onSuccess, onError, favouriteGameId, gameLabelIds) => {
    let requestBody = {
        favouriteGameId: favouriteGameId,
        gameLabelIds: gameLabelIds
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(gameLabelAssociationEndpoint.setLabels(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}