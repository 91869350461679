import { Col, Container, Row } from "react-bootstrap";

function BottomAnchor(props) {
    return (
        <div className="fixed-bottom bg-primary">
            <div className="d-block d-md-none">{/*Visible only on xs and sm*/}
                <Container>
                    <Row className="mb-1">
                        <Col>
                            <a href="#" className="text-white">TOP</a>
                        </Col>
                        {build_alphabet_links(props.favouriteGames, "_mobile")}
                    </Row>
                </Container>
            </div>
            <div className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                <Container>
                    <Row className="mb-1">
                        <Col>
                            <a href="#" className="text-white">TOP</a>
                        </Col>
                        {build_alphabet_links(props.favouriteGames, "_web")}
                    </Row>
                </Container>
            </div>
        </div>
    );
}

function build_alphabet_links(favouriteGames, suffix) {
    let first_letters = [...new Set(favouriteGames.map(
        (favouriteGame) => {
            if (favouriteGame.name) {
                return favouriteGame.name.trim().charAt(0)
            } else {
                return "";
            }
        }
    ))];

    return first_letters.map((first_letter, index) => (
        <Col key={index}>
            <a href={"#" + first_letter + suffix} className="text-white">{first_letter}</a>
        </Col>
    ));
}

export default BottomAnchor;