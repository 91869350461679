import 'bootstrap/dist/css/bootstrap.min.css';
import i18next from "i18next";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { getDefaultLanguage, getLanguage } from './functions/language';
import './index.css';
import messages_en from "./translations/en/messages.json";
import messages_it from "./translations/it/messages.json";

i18next.init({
    interpolation: { escapeValue: false },
    lng: getLanguage() || getDefaultLanguage(),
    resources: {
        en: {
            messages: messages_en
        },
        it: {
            messages: messages_it
        },
    },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <I18nextProvider i18n={i18next}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </I18nextProvider>
);