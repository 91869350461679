import { invokeApi } from "../components/network/apiclient";
import { publicFavouriteGameEndpoint, publicFavouriteGameLabelEndpoint, publicPublicUserEndpoint } from "./endpoint";

export const fetchPublicFavouriteGames = (gameLinkId, onSuccess, onError, filter) => {
    let requestBody = {
        gameLinkId: gameLinkId,
        filter: filter
    };

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(publicFavouriteGameEndpoint.retrievePath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
};

export const fetchPublicLabels = (gameLinkId, onSuccess, onError) => {
    let requestBody = {
        gameLinkId: gameLinkId
    };

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(publicFavouriteGameLabelEndpoint.retrievePath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
};

export const retrievePublicUserInfo = (gameLinkId, onSuccess, onError) => {
    let requestBody = {
        gameLinkId: gameLinkId
    }

    let init = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };
    let myRequest = new Request(publicPublicUserEndpoint.retrievePath(), init);

    invokeApi(myRequest, onSuccess, onError);
};