import { envDev } from '../config/dev';
import { envProd } from '../config/prod';

const getEnv = () => {

    if (window.location.host.indexOf("localhost") === 0) {
        return envDev;
    } else {
        return envProd;
    }
}

const getApiBaseUrl = () => {
    return getEnv().apiBaseUrl;
}

const getApiVersion = () => {
    return getEnv().apiVersion;
}

export const getWebsiteUrl = () => {
    return getEnv().websiteUrl;
}

export const getCognitoInfo = () => {
    return getEnv().cognito;
}

export const authenticationEndpoint = {
    apiPath: "backend/authentication",
    syncUserInfoPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/sync"
    }
};

export const productsEndpoint = {
    apiPath: "backend/products",
    searchPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/search"
    }
};

export const gamesEndpoint = {
    apiPath: "backend/games",
    searchPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/search"
    }
};

export const favouriteGamesEndpoint = {
    apiPath: "backend/favourite-games",
    addPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/add"
    },
    deleteByNamePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/delete-by-name"
    },
    deleteByIdPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/delete-by-id"
    },
    deleteByLinkPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/delete-by-link"
    },
    listPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/list"
    },
    findByFilter: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/find-by-filter"
    }
};

export const gameLabelsEndpoint = {
    apiPath: "backend/game-labels",
    addPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/add"
    },
    deletePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/delete"
    },
    listPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/list"
    },
    renamePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/rename"
    },
};

export const gameLabelAssociationEndpoint = {
    apiPath: "backend/game-label-associations",
    addPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/associate"
    },
    deletePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/disassociate"
    },
    listPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/list-associated-games"
    },
    renamePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/list-associated-labels"
    },
    setLabels: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/set-labels"
    }

};

export const gameLinkEndpoint = {
    apiPath: "backend/game-link",
    getPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/get"
    },
    disablePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/disable"
    },
    generatePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/generate"
    }
};

export const publicFavouriteGameEndpoint = {
    apiPath: "backend/public/games",
    retrievePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/retrieve"
    }
};

export const publicFavouriteGameLabelEndpoint = {
    apiPath: "backend/public/labels",
    retrievePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/retrieve"
    }
};

export const publicPublicUserEndpoint = {
    apiPath: "backend/public/users",
    retrievePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/retrieve"
    }
};

export const gameExtractionEndpoint = {
    apiPath: "backend/game-extraction",
    extractPath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/extract"
    },
    retrievePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/retrieve"
    },
    deletePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/delete"
    },
    selectGamePath: function () {
        return getApiBaseUrl() + "/" + this.apiPath + "/" + getApiVersion() + "/select"
    }
};

export const cognitoEndpoint = {
    authorizePath: function (codeChallenge) {
        let cognitoInfo = getCognitoInfo();
        let clientId = cognitoInfo.client_id;
        let encodedRedirectUri = encodeURIComponent(getWebsiteUrl());
        let queryString = "?client_id=" + clientId + "&response_type=code&scope=openid&redirect_uri=" + encodedRedirectUri + "&code_challenge_method=S256&code_challenge=" + codeChallenge;
        return cognitoInfo.url + "/oauth2/authorize" + queryString;
    }, 
    tokenPath: function() {
        let cognitoInfo = getCognitoInfo();
        return cognitoInfo.url + "/oauth2/token";
    },
    userInfoPath: function() {
        let cognitoInfo = getCognitoInfo();
        return cognitoInfo.url + "/oauth2/userInfo";
    }
    
};