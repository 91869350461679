import React from 'react';
import Table from 'react-bootstrap/Table'
import Image from 'react-bootstrap/Image'


function ProductSearchResultOnMobile({ products }) {
    let img_style = {
        width: '100%',
        height: 'auto'
    };

    let products_fields = (products.map((product) => ([
        <tr key={product.link + "_imageLink"}><td rowSpan={5}><Image style={img_style} src={product.imageLink} /></td></tr>,
        <tr key={product.link + "_link"}><td><a href={product.link} target="_blank" rel="noreferrer">{product.name}</a></td></tr>,
        <tr key={product.link + "_price"}><td>{product.price}</td></tr>,
        <tr key={product.link + "_status"}><td>{product.status}</td></tr>,
        <tr key={product.link + "_shopName"}><td>{product.shopName}</td></tr>,
        <tr key={product.link + "_empty"}><td></td></tr>
    ]))).flat();

    return (
        <Table striped bordered hover responsive size="sm">
            <tbody>
                {products_fields.map((product_field) => (
                    product_field
                ))}
            </tbody>
        </Table>
    );
}

export default ProductSearchResultOnMobile;