import { cognitoEndpoint, getCognitoInfo, getWebsiteUrl } from "./endpoint";

export const getOAuth2Tokens = (authorizationCode, codeVerifier, onSuccess, onError) => {
    const myInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            'grant_type': 'authorization_code',
            'client_id': getCognitoInfo().client_id,
            'code': authorizationCode,
            'redirect_uri': getWebsiteUrl(),
            'code_verifier': codeVerifier
        })
    };

    const myRequest = new Request(cognitoEndpoint.tokenPath(), myInit);

    fetch(myRequest)
        .then((res) => {
            res.json()
                .then((data) => {
                    onSuccess(data);
                });
        })
        .catch((error) => {
            onError(null, error);
        });
}

export const getOAuth2UserInfo = (accessToken, onSuccess, onError) => {
    const myInit = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'Authorization': 'Bearer ' + accessToken
        }
    };

    const myRequest = new Request(cognitoEndpoint.userInfoPath(), myInit);

    fetch(myRequest)
    .then((res) => {
        res.json()
            .then((data) => {
                onSuccess(data);
            });
    })
    .catch((error) => {
        onError(null, error);
    });
}

