import { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function GameLabelRenamer(props) {
    const { t } = useTranslation('messages');

    const [newLabel, setNewLabel] = useState(props.gameLabel.name);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            < Button
                variant="primary"
                onClick={handleShow}
                className="mb-1 me-1" >
                {t('private_area.games_tab.labels_rename_button_text')}
            </Button>
            <Offcanvas show={show} onHide={handleClose} placement="bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{props.gameLabel.name}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form className="mb-3">
                        <Form.Group
                            className="mb-3"
                            controlId={"RenameGameLabelFormGroup_" + props.gameLabel.gameLabelId}>
                            <Form.Control
                                type="text"
                                value={newLabel}
                                placeholder={t('private_area.games_tab.labels_rename_form_placeholder')}
                                onChange={e => setNewLabel(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={(e) => renameLabel(e, props.renameLabel, props.gameLabel.gameLabelId, newLabel, handleClose)}>
                            {t('private_area.games_tab.labels_rename_form_button_text')}
                        </Button>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}


function renameLabel(e, renameLabel, gameLabelId, newLabel, handleClose) {
    e.preventDefault();

    if (!newLabel) {
        return;
    }

    renameLabel(gameLabelId, newLabel);
    handleClose();
}

export default GameLabelRenamer;