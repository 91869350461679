import React, { useState } from 'react';
import FavouriteGameListOnWeb from './FavouriteGameListOnWeb.js'
import FavouriteGameListOnMobile from './FavouriteGameListOnMobile.js'

function FavouriteGameDisplayer(props) {
    const [showEditGame, setShowEditGame] = useState(false);
    const [underEditGame, setUnderEditGame] = useState(null);
    const [underAssociationLabelsMap, setUnderAssociationLabelsMap] = useState(new Map());

    return (
        <div>
            <div className="d-block d-md-none">{/*Visible only on xs and sm*/}
                <FavouriteGameListOnMobile
                    gameLabels={props.gameLabels}
                    favouriteGames={props.favouriteGames}
                    associateLabels={props.associateLabels}
                    deleteGame={props.deleteGame}
                    showEditGame={showEditGame}
                    underEditGame={underEditGame}
                    underAssociationLabelsMap={underAssociationLabelsMap}
                    setUnderAssociationLabelsMap={setUnderAssociationLabelsMap}
                    onShowGameEditor={(gameId) => onShowGameEditor(gameId, setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap)}
                    onCloseGameEditor={() => onCloseGameEditor(setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap)}
                    onSearchProductClicked={props.onSearchProductClicked}
                />
            </div>
            <div className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                <FavouriteGameListOnWeb
                    gameLabels={props.gameLabels}
                    favouriteGames={props.favouriteGames}
                    associateLabels={props.associateLabels}
                    deleteGame={props.deleteGame}
                    showEditGame={showEditGame}
                    underEditGame={underEditGame}
                    underAssociationLabelsMap={underAssociationLabelsMap}
                    setUnderAssociationLabelsMap={setUnderAssociationLabelsMap}
                    onShowGameEditor={(gameId) => onShowGameEditor(gameId, setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap)}
                    onCloseGameEditor={() => onCloseGameEditor(setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap)}
                    onSearchProductClicked={props.onSearchProductClicked}
                />
            </div>
        </div>
    );
}

function onShowGameEditor(game, setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap) {
    setShowEditGame(true);
    setUnderEditGame(game);

    let map = game.gameLabels.reduce((map, gameLabel) => {
        map.set(gameLabel.gameLabelId, true);
        return map;
    }, new Map());

    setUnderAssociationLabelsMap(map)
}

function onCloseGameEditor(setShowEditGame, setUnderEditGame, setUnderAssociationLabelsMap) {
    setShowEditGame(false);
    setUnderEditGame(null);
    setUnderAssociationLabelsMap(new Map());
}

export default FavouriteGameDisplayer;