import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_GAMES_LIBRARY, ROUTE_GAME_EXTRACTIONS, ROUTE_PUBLIC_LINK, ROUTE_RANDOM_GAME } from "../../functions/route";

function PrivateAreaMobile(props) {
    const { t } = useTranslation('messages');
    let navigate = useNavigate();

    return (
        <>
            <Row style={{ height: 400 }}>
                <Col xs={12} className="square border border-dark rounded border-2 text-center mb-5">
                    <Button variant="outline-light" className="text-black" onClick={() => navigate("/" + ROUTE_GAMES_LIBRARY)}>
                        <Container>
                            <Row>
                                <Col className="pt-2">
                                    <img src="./images/game-library.png" alt={t('private_area.games_box_title')} height="60" />
                                </Col>
                                <Col className="pt-2">
                                    <h5>{t('private_area.games_box_title')}</h5>
                                    <br />
                                    <span>{t('private_area.games_box_description')}</span>
                                </Col>
                            </Row>
                        </Container>
                    </Button>
                </Col>
                <Col xs={12} className="square border border-dark rounded border-2 text-center mb-5">
                    <Button variant="outline-light" className="text-black" onClick={() => navigate("/" + ROUTE_RANDOM_GAME)}>
                        <Container>
                            <Row>
                                <Col className="pt-2">
                                    <img src="./images/random-game.png" alt={t('private_area.random_games_box_title')} height="60" />
                                </Col>
                                <Col className="pt-2">
                                    <h5>{t('private_area.random_games_box_title')}</h5>
                                    <br />
                                    <span>{t('private_area.random_games_box_description')}</span>
                                </Col>
                            </Row>
                        </Container>
                    </Button>
                </Col>
                <Col xs={12} className="square border border-dark rounded border-2 text-center mb-5">
                    <Button variant="outline-light" className="text-black" onClick={() => navigate("/" + ROUTE_GAME_EXTRACTIONS)}>
                        <Container>
                            <Row>
                                <Col className="pt-2">
                                    <img src="./images/game-extraction.png" alt={t('private_area.game_extraction_box_title')} height="60" />
                                </Col>
                                <Col className="pt-2">
                                    <h5>{t('private_area.game_extraction_box_title')}</h5>
                                    <br />
                                    <span>{t('private_area.game_extraction_box_description')}</span>
                                </Col>
                            </Row>
                        </Container>
                    </Button>
                </Col>
                <Col xs={12} className="square border border-dark rounded border-2 text-center">
                    <Button variant="outline-light" className="text-black" onClick={() => navigate("/" + ROUTE_PUBLIC_LINK)}>
                        <Container>
                            <Row>
                                <Col className="pt-2">
                                    <img src="./images/game-link.png" alt={t('private_area.game_link_box_title')} height="60" />
                                </Col>
                                <Col className="pt-2">
                                    <h5>{t('private_area.game_link_box_title')}</h5>
                                    <br />
                                    <span>{t('private_area.game_link_box_description')}</span>
                                </Col>
                            </Row>
                        </Container>
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default PrivateAreaMobile;