const LOCAL_STORAGE_ACCESS_TOKEN_KEY = "access_token";
const LOCAL_STORAGE_ID_TOKEN_KEY = "id_token";
const LOCAL_STORAGE_REFRESH_TOKEN_KEY = "refresh_token";
const LOCAL_STORAGE_USER_INFO_KEY = "userInfo";
const LOCAL_STORAGE_CODE_VERIFIER = "codeVerifier";

export const isLoggedIn = () => {
    return (
        window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY) != null
        && window.localStorage.getItem(LOCAL_STORAGE_ID_TOKEN_KEY) != null
        && window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY) != null
        && window.localStorage.getItem(LOCAL_STORAGE_USER_INFO_KEY) != null);
}

export const getAccessToken = () => {
    return window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
}

export const getIdToken = () => {
    return window.localStorage.getItem(LOCAL_STORAGE_ID_TOKEN_KEY);
}

export const getRefreshToken = () => {
    return window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
}

export const getUserInfo = () => {
    return window.localStorage.getItem(LOCAL_STORAGE_USER_INFO_KEY);
}

export const getCodeVerifier = () => {
    return window.localStorage.getItem(LOCAL_STORAGE_CODE_VERIFIER);
}

export const deleteAuthenticationInfo = () => {
    window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_ID_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_USER_INFO_KEY);
    window.localStorage.removeItem(LOCAL_STORAGE_CODE_VERIFIER);
}

export const setAuthenticationInfo = (accessToken, idToken, refreshToken, userInfo) => {
    window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY, accessToken);
    window.localStorage.setItem(LOCAL_STORAGE_ID_TOKEN_KEY, idToken);
    window.localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken);
    window.localStorage.setItem(LOCAL_STORAGE_USER_INFO_KEY, JSON.stringify(userInfo));
}

export const setCodeVerifier = (codeVerifier) => {
    window.localStorage.setItem(LOCAL_STORAGE_CODE_VERIFIER, codeVerifier);
}