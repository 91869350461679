import { Accordion, Badge, Button, Col, Container, Image, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function GameExtractionOnMobile({ token, onError, accordionIndex, extraction, onSearchProductClicked, onDeleteExtractionClicked, onSelectGameClicked }) {
    const { t } = useTranslation('messages');

    return (
        <Accordion.Item eventKey={accordionIndex}>
            <Accordion.Header>
                <Stack direction="vertical" gap={2} className="d-block">
                    <Badge bg="secondary">{extraction.creation_time}</Badge>
                    {extraction.game_filters.map((filter) => (
                        <Badge bg="success" key={filter.game_label_id}>{filter.name}</Badge>
                    ))}
                    {extraction.game_negative_filters.map((filter) => (
                        <Badge bg="danger" key={filter.game_label_id}>{filter.name}</Badge>
                    ))}
                </Stack>
            </Accordion.Header>
            <Accordion.Body>
                <Container>
                    <Row className="pb-2">
                        <Col>
                            <Button
                                variant="danger"
                                onClick={(e) => onDeleteExtractionClicked(e)}>
                                {t('private_area.game_extractions_tab.delete_button_text')}
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover responsive size="sm">
                                <tbody>
                                    {extraction.games.map((game) => (
                                        [
                                            <tr key={game.favourite_game_id + "_image"}>
                                                <td><Image src={game.image_link} /></td>
                                                <td><a href={game.link} target="_blank" rel="noreferrer">{game.name}</a></td>
                                            </tr>,
                                            <tr key={game.favourite_game_id + "_description"}>
                                                <td>{game.description}</td>
                                                <td>
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        onClick={() => onSearchProductClicked(game.name)}>
                                                        {t('private_area.game_extractions_tab.search_button_text')}
                                                    </Button>
                                                </td>
                                            </tr>,
                                            <tr key={game.favourite_game_id + "_selected"}>
                                                <td></td>
                                                <td>
                                                    <Button
                                                        variant="primary"
                                                        type="button"
                                                        onClick={(e) =>
                                                            onSelectGameClicked(e, extraction.game_extraction_id, game.game_extraction_result_id, !game.selected)}>
                                                        {game.selected
                                                            ? <img src="./images/star_yellow.png" alt="" height="20" />
                                                            : <img src="./images/star_white.png" alt="" height="20" />}
                                                    </Button>
                                                </td>
                                            </tr>

                                        ]
                                    )).flat()}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default GameExtractionOnMobile;