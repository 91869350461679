import { Button, Col, Form, Row, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ProductSearchConfiguration(props) {
    const { t } = useTranslation('messages');

    let shopIds = props.shopList.map((shop) => shop.id);

    return (
        <>
            <Row>
                <Col>
                    <Form>
                        {
                            props.shopList.map((shop) => (
                                <Form.Check
                                    key={shop.id}
                                    type="checkbox"
                                    label={shop.name}
                                    checked={props.checkedShops.includes(shop.id)}
                                    onChange={(e) => {
                                        checkShopCheckbox(
                                            e.target.checked,
                                            shop.id,
                                            shopIds,
                                            props.checkedShops,
                                            props.setCheckedShops,
                                            props.setSelectAllChecked)
                                    }}
                                    inline
                                />
                            ))
                        }
                    </Form>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            label={t('main.store_search.checkbox_only_available')}
                            checked={props.onlyAvailableChecked}
                            onChange={(e) => props.setOnlyAvailableChecked(e.target.checked)}
                            inline
                        />
                        <Form.Check
                            type="checkbox"
                            label={t('main.store_search.checkbox_all_shops')}
                            checked={props.selectAllChecked}
                            onChange={(e) => checkAllShops(e.target.checked, shopIds, props.setCheckedShops, props.setSelectAllChecked)}
                            inline

                        />
                    </Form>
                </Col>
            </Row>
            <Row className="mt-3 pb-3">
                <Col>
                    <Form id="product_search_form">
                        <Form.Group className="mb-3">
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    value={props.searchValue}
                                    placeholder={t('main.store_search.search_form_placeholder')}
                                    onChange={e => props.setSearchValue(e.target.value)}
                                />
                                <Button variant="primary" onClick={() => props.setSearchValue("")}>
                                    {t('main.store_search.search_form_delete_query_button_text')}
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={(e) => onSearchProductsClicked(e, props.fetchProducts)}>
                            {t('main.store_search.search_form_button_text')}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

function checkShopCheckbox(checked, shopIds, shopNames, checkedShops, setCheckedShops, setSelectAllChecked) {
    const checkedIndex = checkedShops.indexOf(shopIds);

    if (checked && checkedIndex < 0) {
        checkedShops.push(shopIds);
    } else if (!checked && checkedIndex >= 0) {
        checkedShops.splice(checkedIndex, 1);
    }
    setCheckedShops([...checkedShops]);

    if (shopNames.every(r => checkedShops.includes(r))) {
        setSelectAllChecked(true);
    } else {
        setSelectAllChecked(false);
    }
}

function checkAllShops(checked, shopIds, setCheckedShops, setSelectAllChecked) {
    setSelectAllChecked(checked);
    if (checked) {
        setCheckedShops([...shopIds]);
    } else {
        setCheckedShops([]);
    }
}

function onSearchProductsClicked(e, fetchProducts) {
    e.preventDefault();
    fetchProducts();
}

export default ProductSearchConfiguration;