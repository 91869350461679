export const ROUTE_PRODUCT_SEARCH = "product_search";
export const ROUTE_GAME_SEARCH = "game_search";
export const ROUTE_GAMES_LIBRARY = "games_library";
export const ROUTE_RANDOM_GAME = "random_game";
export const ROUTE_GAME_EXTRACTIONS = "game_extractions"
export const ROUTE_PUBLIC_LINK = "public_link";
export const ROUTE_PUBLIC_GAMES = "public/games";
export const ROUTE_LOGOUT = "logout";
export const ROUTE_PAGE_NOT_FOUND = "page_not_found";
export const ROUTE_PRIVATE_AREA = "private_area";
export const ROUTE_ABOUT = "about";