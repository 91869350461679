import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import { Outlet } from "react-router-dom";
import { getUserInfo, isLoggedIn, setCodeVerifier } from '../functions/authentication';
import { setLanguage } from '../functions/language';
import { ROUTE_ABOUT, ROUTE_GAME_SEARCH, ROUTE_PRIVATE_AREA, ROUTE_PRODUCT_SEARCH } from '../functions/route';
import { cognitoEndpoint } from '../functions/endpoint';

function Navigation(props) {

    const { t, i18n } = useTranslation('messages');

    if (isLoggedIn()) {
        return (
            <div>
                <Navbar bg="primary" variant="dark" expand="lg">
                    <Container>
                        <Navbar.Brand>{t('main.navbar.brand')}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/">{t('main.navbar.home')}</Nav.Link>
                                <Nav.Link href={"/" + ROUTE_PRODUCT_SEARCH}>{t('main.navbar.store_search')}</Nav.Link>
                                <Nav.Link href={"/" + ROUTE_GAME_SEARCH}>{t('main.navbar.game_search')}</Nav.Link>
                                <Nav.Link href={"/" + ROUTE_ABOUT}>{t('main.navbar.about')}</Nav.Link>
                            </Nav>
                            <Nav>
                                <NavDropdown title={t('main.navbar.language')} id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={() => changeLanguage(i18n, 'en', setLanguage)}>
                                        {t('main.navbar.language_en')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => changeLanguage(i18n, 'it', setLanguage)}>
                                        {t('main.navbar.language_it')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Nav>
                                <Nav.Link href={"/" + ROUTE_PRIVATE_AREA}>{t('main.navbar.greeting', { name: JSON.parse(getUserInfo()).firstname })}</Nav.Link>
                                <Nav.Link href="/logout">{t('main.navbar.logout')}</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Outlet />
            </div>
        )

    } else {
        return (
            <div>
                <Navbar bg="primary" variant="dark" expand="lg">
                    <Container>
                        <Navbar.Brand>{t('main.navbar.brand')}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/">{t('main.navbar.home')}</Nav.Link>
                                <Nav.Link href={"/" + ROUTE_ABOUT}>{t('main.navbar.about')}</Nav.Link>
                            </Nav>
                            <Nav>
                                <NavDropdown title={t('main.navbar.language')} id="basic-nav-dropdown">
                                    <NavDropdown.Item onClick={() => changeLanguage(i18n, 'en', setLanguage)}>
                                        {t('main.navbar.language_en')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => changeLanguage(i18n, 'it', setLanguage)}>
                                        {t('main.navbar.language_it')}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <Nav>
                                <Nav.Link onClick={() => redirectToLoginUri()}>{t('main.navbar.login')}</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Outlet />
            </div>
        )
    }
}

function changeLanguage(i18n, lang, setLanguage) {
    i18n.changeLanguage(lang);
    setLanguage(lang);
}

function redirectToLoginUri() {
    const codeVerifier = generateRandomString(36);
    generateCodeChallenge(codeVerifier).then((codeChallenge) => {
        setCodeVerifier(codeVerifier);
        window.location.href = cognitoEndpoint.authorizePath(codeChallenge);
    });
}

function generateRandomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}

async function generateCodeChallenge(codeVerifier) {
    var digest = await crypto.subtle.digest("SHA-256",
        new TextEncoder().encode(codeVerifier));

    return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}


export default Navigation;