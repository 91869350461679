import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { fetchProducts } from "../../functions/product";
import { shopList } from "../../functions/shop";
import Loader from "../Loader";
import ProductSearchConfiguration from "./ProductSearchConfiguration";
import ProductSearchResultDisplayer from "./ProductSearchResultDisplayer";

const LoadingStatus = Object.freeze({
    'LOADED': 'LOADED',
    'LOADING': 'LOADING'
});

export const PARAM_SEARCH_VALUE = "search_value";

function ProductSearcher({ token, onError }) {
    const { t } = useTranslation('messages');

    const [searchParams] = useSearchParams();
    let searchValueParam = searchParams.get(PARAM_SEARCH_VALUE)

    const [searchValue, setSearchValue] = useState("");
    const [products, setProducts] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.LOADED);
    const [checkedShops, setCheckedShops] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [onlyAvailableChecked, setOnlyAvailableChecked] = useState(false);

    useEffect(() =>
        searchFromQueryString(
            token, searchValueParam, shopList,
            setCheckedShops, setSelectAllChecked, setOnlyAvailableChecked,
            setSearchValue, searchProducts, setProducts,
            setLoadingStatus, onError),
        [token, searchValueParam, onError]
    );

    let search_result;
    if (loadingStatus === LoadingStatus.LOADED) {
        search_result = (
            <Row className="mt-3">
                <Col>
                    <ProductSearchResultDisplayer products={products} />
                </Col>
            </Row>
        );
    } else {
        search_result = (
            <Row className="mt-3 justify-content-center">
                <Col xs="auto">
                    <Loader />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Container fluid className="bg-dark text-white">
                <Container className="pt-5 pb-5">
                    <Row>
                        <Col xs="auto">
                            <h5>{t('main.store_search.title')}</h5>
                        </Col>
                    </Row>
                    <ProductSearchConfiguration
                        fetchProducts={() =>
                            searchProducts(token, searchValue, checkedShops, onlyAvailableChecked, setProducts, setLoadingStatus, onError)
                        }
                        shopList={shopList}
                        checkedShops={checkedShops}
                        setCheckedShops={setCheckedShops}
                        selectAllChecked={selectAllChecked}
                        setSelectAllChecked={setSelectAllChecked}
                        onlyAvailableChecked={onlyAvailableChecked}
                        setOnlyAvailableChecked={setOnlyAvailableChecked}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </Container>
            </Container>
            <Container fluid>
                <Container>
                    {search_result}
                </Container>
            </Container>
        </>
    );
}

function searchFromQueryString(
    token, searchValueParam, shopList,
    setCheckedShops, setSelectAllChecked, setOnlyAvailableChecked,
    setSearchValue, searchProducts, setProducts,
    setLoadingStatus, onError) {
    if (!searchValueParam) {
        return;
    }
    let shopIds = shopList.map(shop => shop.id);

    setCheckedShops(shopIds);
    setSelectAllChecked(true);
    setOnlyAvailableChecked(true);
    setSearchValue(searchValueParam);
    searchProducts(token, searchValueParam, shopIds, true, setProducts, setLoadingStatus, onError);
}

function searchProducts(token, searchValue, checkedShops, onlyAvailableChecked, setProducts, setLoadingStatus, onError) {
    if (checkedShops.length < 1) {
        return;
    }

    if (!searchValue) {
        return;
    }

    setProducts([]);
    setLoadingStatus(LoadingStatus.LOADING);

    fetchProducts(
        token,
        (data) => {
            setProducts(data.products);
            setLoadingStatus(LoadingStatus.LOADED);
        },
        (res, err) => {
            setProducts([]);
            setLoadingStatus(LoadingStatus.LOADED);
            onError(res, err);
        },
        searchValue,
        checkedShops,
        onlyAvailableChecked
    )
}

export default ProductSearcher;