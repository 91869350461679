import React from 'react';
import { Button, Image, Table } from 'react-bootstrap';

function PublicFavouriteGameListOnMobile({ favouriteGames }) {

    return (
        <>
            <Table striped bordered hover responsive size="sm">
                <tbody>
                    {favouriteGames.map((favouriteGame) => ([
                        <tr key={favouriteGame.favouriteGameId + "_image"}>
                            <td>
                                <div id={favouriteGame.name.charAt(0) + "_mobile"}></div>
                                <Image src={favouriteGame.imageLink} />
                            </td>
                            <td>
                                <a href={favouriteGame.link} target="_blank" rel="noreferrer">{favouriteGame.name}
                                </a>
                            </td>

                        </tr>,
                        <tr key={favouriteGame.favouriteGameId + "_description"}>
                            <td colSpan="2">
                                {favouriteGame.description}
                            </td>
                        </tr>,
                        <tr key={favouriteGame.favouriteGameId + "_labels"}>
                            <td colSpan="2">
                                {favouriteGame.gameLabels && favouriteGame.gameLabels.map((gameLabel) => (
                                    <Button
                                        key={gameLabel.gameLabelId}
                                        disabled={true}
                                        variant="secondary"
                                        className="mt-1 me-1">
                                        {gameLabel.name}
                                    </Button>
                                ))}
                            </td>
                        </tr>,
                        <tr key={favouriteGame.favouriteGameId + "_empty"}><td colSpan="2"></td></tr>
                    ])).flat()}
                </tbody>
            </Table>
        </>
    );
}

export default PublicFavouriteGameListOnMobile;