import { invokeApi } from "../components/network/apiclient";
import { productsEndpoint } from "./endpoint";

export const fetchProducts = (token, onSuccess, onError, searchValue, shopNames, onlyAvailable) => {
    let search_product_request = {
        query: searchValue,
        shopNames: shopNames,
        onlyAvailable: onlyAvailable
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(search_product_request)
    };

    const myRequest = new Request(productsEndpoint.searchPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}