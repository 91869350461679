import React from 'react';
import { Button, Image, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FavouriteGameEditor from '../edit/FavouriteGameEditor';

function FavouriteGameListOnMobile(props) {
    const { t } = useTranslation('messages');

    let games_fields = (props.favouriteGames.map((favouriteGame) => ([
        <tr key={favouriteGame.favouriteGameId + "_image"}>
            <td>
                <div id={favouriteGame.name.charAt(0) + "_mobile"}></div>
                <Image src={favouriteGame.imageLink} />
            </td>
            <td>
                <a href={favouriteGame.link} target="_blank" rel="noreferrer">{favouriteGame.name}
                </a>
            </td>
            <td>
                < Button
                    variant="primary"
                    onClick={() => props.onShowGameEditor(favouriteGame)}>
                    {t('private_area.games_tab.games_edit_button_text')}
                </Button>
            </td>
        </tr>,
        <tr key={favouriteGame.favouriteGameId + "_search"}>
            <td colSpan="2">{favouriteGame.description}</td>
            <td>
                <Button
                    variant="primary"
                    type="button"
                    onClick={() => props.onSearchProductClicked(favouriteGame.name)}>
                    {t('private_area.games_tab.games_serch_button_text')}
                </Button>
            </td>
        </tr>,
        <tr key={favouriteGame.favouriteGameId + "_creationTime"}>
            <td colSpan="2">
                {favouriteGame.creationTime ? new Date(favouriteGame.creationTime).toLocaleString() : null}
            </td>
            <td>
                <Button
                    variant="danger"
                    value="Delete"
                    type="button"
                    onClick={() => props.deleteGame(favouriteGame.favouriteGameId)}>
                    {t('private_area.games_tab.games_delete_button_text')}
                </Button>
            </td>
        </tr>,
        <tr key={favouriteGame.favouriteGameId + "_gameLabels"}>
            <td colSpan="3">
                {favouriteGame.gameLabels && favouriteGame.gameLabels.map((gameLabel) => (
                    <Button
                        key={gameLabel.gameLabelId}
                        disabled={true}
                        variant="secondary"
                        className="mt-1 me-1">
                        {gameLabel.name}
                    </Button>
                ))}
            </td>
        </tr>,
        <tr key={favouriteGame.favouriteGameId + "_empty"}><td colSpan="3"></td></tr>
    ]))).flat();

    return (
        <>
            <Table striped bordered hover responsive size="sm">
                <tbody>
                    {games_fields.map((game_field) => (
                        game_field
                    ))}
                </tbody>
            </Table>
            <FavouriteGameEditor
                gameLabels={props.gameLabels}
                associateLabels={props.associateLabels}
                showEditGame={props.showEditGame}
                underAssociationLabelsMap={props.underAssociationLabelsMap}
                setUnderAssociationLabelsMap={props.setUnderAssociationLabelsMap}
                underEditGame={props.underEditGame}
                onCloseGameEditor={props.onCloseGameEditor} />
        </>
    );
}

export default FavouriteGameListOnMobile;