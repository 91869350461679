import { invokeApi } from '../components/network/apiclient';
import { favouriteGamesEndpoint } from '../functions/endpoint'

export const fetchFavouriteGames = (token, onSuccess, onError, filter) => {
    let requestBody = {
        filter: filter
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(favouriteGamesEndpoint.findByFilter(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const addFavouriteGame = (token, onSuccess, onError, favouriteGameInfo) => {
    let requestBody = {
        favouriteGameInfo: favouriteGameInfo
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(favouriteGamesEndpoint.addPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const deleteFavouriteGameByName = (token, onSuccess, onError, gameName) => {
    let requestBody = {
        name: gameName
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(favouriteGamesEndpoint.deleteByNamePath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}

export const deleteFavouriteGameById = (token, onSuccess, onError, gameId) => {
    let requestBody = {
        favouriteGameId: gameId
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(favouriteGamesEndpoint.deleteByIdPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}


export const deleteFavouriteGameByLink = (token, onSuccess, onError, game) => {
    let requestBody = {
        link: game.link
    }

    const myInit = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(requestBody)
    };

    const myRequest = new Request(favouriteGamesEndpoint.deleteByLinkPath(), myInit);

    invokeApi(myRequest, onSuccess, onError);
}