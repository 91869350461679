import React from 'react';
import ProductSearchResultOnMobile from './ProductSearchResultOnMobile';
import ProductSearchResultOnWeb from './ProductSearchResultOnWeb';

function ProductSearchResultDisplayer({ products }) {
    return (
        <div>
            <div className="d-block d-md-none">{/*Visible only on xs and sm*/}
                <ProductSearchResultOnMobile products={products} />
            </div>
            <div className="d-none d-md-block">{/*Hidden only on xs and sm*/}
                <ProductSearchResultOnWeb products={products} />
            </div>
        </div>
    );
}

export default ProductSearchResultDisplayer;