import { useState } from "react";
import { Form } from "react-bootstrap";
import GameLabelDisplayer from "./view/GameLabelDisplayer"
import GameLabelEditor from "./edit/GameLabelEditor";
import { useTranslation } from "react-i18next";

function GameLabelArea(props) {
    const { t } = useTranslation('messages');

    const [editSwitchOn, setEditSwitchOn] = useState(false);

    return (
        <>
            <Form className="mb-3">
                <Form.Check
                    type="switch"
                    label={t('private_area.games_tab.labels_edit_switch_label')}
                    defaultChecked={editSwitchOn}
                    onClick={() => setEditSwitchOn(!editSwitchOn)}
                />
            </Form>
            {
                editSwitchOn ?
                    <GameLabelEditor
                        gameLabels={props.gameLabels}
                        addNewLabel={props.addNewLabel}
                        deleteLabel={props.deleteLabel}
                        renameLabel={props.renameLabel}
                    />
                    :
                    <GameLabelDisplayer
                        button_id_prefix="GameLabelArea"
                        gameLabels={props.gameLabels}
                        checkedLabels={props.checkedLabels}
                        checkLabel={props.checkLabel}
                        addNewLabel={props.addNewLabel}
                    />
            }
        </>
    );
}

export default GameLabelArea;