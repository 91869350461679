import React from 'react';
import { Badge } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';

function GameSearchResultOnWeb(props) {
    const { t } = useTranslation('messages');

    return (
        <Table striped bordered hover responsive size="sm">
            <tbody>
                {props.games.map((game, index) =>
                    renderGame(game, index, props, t)
                )}
            </tbody>
        </Table>
    );
}

function renderGame(game, index, props, t) {
    let favouriteIndex = props.favouriteGames.map(g => g.link).indexOf(game.link);
    let isFavourite = favouriteIndex > -1;
    let favouriteGame = isFavourite ? props.favouriteGames[favouriteIndex] : null;

    return (
        <tr key={index}>
            <td><Image src={game.imageLink} /></td>
            <td><a href={game.link} target="_blank" rel="noreferrer">{game.name}</a></td>
            <td>{game.description}</td>
            <td>
                {favouriteGame && favouriteGame.gameLabels && favouriteGame.gameLabels.map((gameLabel) => (
                    <Badge
                        key={gameLabel.gameLabelId}
                        bg="secondary"
                        className="mt-1 me-1">
                        {gameLabel.name}
                    </Badge>
                ))}
            </td>
            <td>
                {renderEditGameButton(isFavourite, favouriteGame, props.onShowGameEditor, t)}
            </td>
            <td>{renderAddOrDeleteButton(isFavourite, props.onAddGameClicked, props.onDeleteGameClicked, game, t)}</td>
            <td>
                <Button
                    variant="primary"
                    type="button"
                    onClick={() => props.onSearchProductClicked(game.name)}>
                    {t('main.game_search.result_search_button_text')}
                </Button>
            </td>
        </tr>
    )
}

function renderEditGameButton(isFavourite, favouriteGame, onShowGameEditor, t) {
    if (isFavourite) {
        return (
            < Button
                variant="primary"
                onClick={() => onShowGameEditor(favouriteGame)}>
                {t('private_area.games_tab.games_edit_button_text')}
            </Button>
        );
    } else {
        return (<>
        </>);
    }
}

function renderAddOrDeleteButton(isFavourite, onAddGameClicked, onDeleteGameClicked, game, t) {

    if (isFavourite) {
        return (
            <Button
                variant="danger"
                type="button"
                onClick={() => onDeleteGameClicked(game)}>
                {t('main.game_search.result_delete_button_text')}
            </Button>
        )
    } else {
        return (
            <Button
                variant="primary"
                type="button"
                onClick={() => onAddGameClicked(game)}>
                {t('main.game_search.result_add_button_text')}
            </Button>
        )
    }
}

export default GameSearchResultOnWeb;