export const invokeApi = (request, onSuccess, onError) => {
    fetch(request)
        .then((res) => {
            if (isError(res)) {
                onError(res, null);
            } else {
                res.json()
                    .then((data) => {
                        onSuccess(data);
                    });
            }
        })
        .catch((error) => {
            onError(null, error);
        });
}

export const isError = (res) => {
    return res.status !== 200 || res.headers.get('error-code') !== "0";
}
