import React from 'react';
import { Button, Image, Table } from 'react-bootstrap';

function PublicFavouriteGameListOnWeb({ favouriteGames }) {

    return (
        <>
            <Table striped bordered hover responsive size="sm">
                <tbody>
                    {favouriteGames.map((favouriteGame) => (
                        <tr key={favouriteGame.favouriteGameId}>
                            <td>
                                <div id={favouriteGame.name.charAt(0) + "_web"}></div>
                                <Image src={favouriteGame.imageLink} />
                            </td>
                            <td>
                                <a href={favouriteGame.link} target="_blank" rel="noreferrer">{favouriteGame.name}
                                </a>
                            </td>
                            <td>
                                {favouriteGame.description}
                            </td>
                            <td>
                                {favouriteGame.gameLabels && favouriteGame.gameLabels.map((gameLabel) => (
                                    <Button
                                        key={gameLabel.gameLabelId}
                                        disabled={true}
                                        variant="secondary"
                                        className="mt-1 me-1">
                                        {gameLabel.name}
                                    </Button>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
}

export default PublicFavouriteGameListOnWeb;