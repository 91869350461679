import React from "react";
import Spinner from 'react-bootstrap/Spinner'
import { useTranslation } from "react-i18next";

function Loader({ isError }) {
    const { t } = useTranslation('messages');

    let toRender;

    if (isError) {
        toRender = (
            <Spinner animation="border" role="status" variant='danger'>
                <span className="visually-hidden">{t('loading.spinner.error')}</span>
            </Spinner>
        );
    } else {
        toRender = (
            <Spinner animation="border" role="status">
                <span className="visually-hidden">{t('loading.spinner.loading')}</span>
            </Spinner>
        );
    }

    return toRender;
}

export default Loader;